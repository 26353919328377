import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '@molecules';
import { SimpleHero, NewsListing } from '@organisms';
import { setSearchParams, getKeyValue } from '@utils';

const ContentNewsListingTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale, newsItems, newsItemPageCount, newsItemPage, slug } = pageContext;
  const { siteTitle, sharedLabels } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent,
    disableAlertBar, enLanguagePageLinks, pageInOtherLanguages,
  } = page || {};
  const {
    title, intro,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};

  const searchParams = setSearchParams(location);
  return (
    <Layout
      location={location}
      metaDescription={metaDescription &&  metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme="transparent"
      settings={settings}
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
      footerBackground="white"
      heroLogo="lightblue"
    >
      <SimpleHero
       title={title}
       heroColor="lightblue"
       intro={intro}
      />
      {newsItems && (
        <NewsListing sharedLabels={sharedLabels} newsItems={newsItems} />
      )}
    </Layout>
  );
};

export default ContentNewsListingTemplate;

export const newsListingPageQuery = graphql`
  query ContentNewsListingBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentContentListing {
          title
          intro {
            raw
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
